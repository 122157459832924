
import { ROOT_ACTIONS } from '@/store/actions';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

import * as FactcheckServices from '@/services/FactCheckService';
import * as SeoServices from '@/services/SeoServices';
import { Factcheck } from '@/types';

@Component
export default class CreateApiKey extends Vue {
    @VModel({ required: true }) show!: boolean;
    @Prop({ required: true }) factcheckId!: string;

    loading = false;
    generationLoading = false;

    factCheck: Factcheck | null = null;

    inputs = {
        description: '',
        keywords: [] as string[]
    }

    async mounted() {
        const factcheck = await FactcheckServices.GetFactCheck(this.factcheckId)
        if (factcheck) this.factCheck = factcheck
        console.log(factcheck)
        if (factcheck?.FactcheckSeoConfig) {
            this.inputs.description = factcheck.FactcheckSeoConfig.description
            this.inputs.keywords = factcheck.FactcheckSeoConfig.keywords.split(',')
        }
    }

    async submit() {
        try {
            this.loading = true;

            await FactcheckServices.UpdateSEO(this.factcheckId, {
                description: this.inputs.description,
                keywords: this.inputs.keywords.join(',')
            })
            this.$emit('refresh')
            this.inputs = {
                description: '',
                keywords: [] as string[]
            }
            this.show = false;
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: error
            })
        } finally {
            this.loading = false;
        }
    }

    async generate() {
        try {
            this.generationLoading = true;

            const seo = await SeoServices.GenerateFactcheckSeoData(this.factcheckId)
            this.inputs.description = seo.description
            this.inputs.keywords = seo.keywords.split(',')
        } catch (error) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: error
            })
        } finally {
            this.generationLoading = false;
        }
    }
}
